import {
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../environment";
import {
  OpenInvoicesPageQuery,
  OpenInvoicesPageQuery$data,
} from "./__generated__/OpenInvoicesPageQuery.graphql";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((t) => ({
  revokedInvoice: {
    color: t.palette.grey[500],
  },
}));

export const OpenInvoicesPage: React.FunctionComponent<{}> = () => {
  return (
    <QueryRenderer<OpenInvoicesPageQuery>
      environment={environment}
      query={graphql`
        query OpenInvoicesPageQuery {
          usersHavingOpenInvoices {
            id
            email
            openInvoices {
              reference
              referenceAsOnInvoice
              createdSeconds
              webProductId
              amountChf
              isRevoked
              customer {
                address
                locality
                name
              }
            }
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <div>Loading...</div>;
        }
        return <PageContent queryResponse={props} />;
      }}
    />
  );
};

const PageContent: React.FunctionComponent<{
  queryResponse: OpenInvoicesPageQuery$data;
}> = (props) => {
  const rows = props.queryResponse.usersHavingOpenInvoices
    .flatMap((u) =>
      (u.openInvoices ?? []).map((i) => ({
        userUid: u.id,
        email: u.email,
        created: new Date(i.createdSeconds * 1000),
        reference: i.reference,
        referenceAsOnInvoice: i.referenceAsOnInvoice,
        isRevoked: i.isRevoked,
        webProductId: i.webProductId,
        amountChf: i.amountChf,
        name: i.customer.name,
      }))
    )
    .sort((a, b) => a.created.valueOf() - b.created.valueOf());
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Referenz</TableCell>
          <TableCell>Rechnungsdatum</TableCell>
          <TableCell>Betrag</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Benutzer ID</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((r) => (
          <TableRow key={r.reference}>
            <TableCell
              align="right"
              className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
            >
              {r.reference}
            </TableCell>
            <TableCell
              className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
            >
              {r.referenceAsOnInvoice}
            </TableCell>
            <TableCell
              align="right"
              className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
            >
              {r.created.toLocaleDateString()}
            </TableCell>
            <TableCell
              align="right"
              className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
            >
              {r.amountChf}
            </TableCell>
            <TableCell
              className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
            >
              {r.name}
            </TableCell>
            <TableCell>
              <Link component={RouterLink} to={`/users/${r.userUid}`}>
                {r.userUid}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
