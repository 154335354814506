import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { ChartIcon, GroupIcon, ReceiptIcon } from "./crossCutting/icons";

export const DrawerMenu: FunctionComponent<{}> = () => {
  return (
    <List component="div">
      <DrawerMenuItem
        text="Offene Rechnungen"
        path="/openInvoices"
        icon={<ReceiptIcon />}
      />
      <DrawerMenuItem text="Benutzer" path="/users" icon={<GroupIcon />} />
      <DrawerMenuItem text="Verkäufe" path="/sales" icon={<ChartIcon />} />
    </List>
  );
};

function DrawerMenuItem(props: {
  text: string;
  path: string;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
  notExact?: boolean;
}) {
  // const classes = useDrawerMenuItemStyles();

  return (
    <ListItem
      button
      component={NavLink}
      to={props.path}
      exact={!props.notExact}
      activeClassName="Mui-selected"
      onClick={props.onClick}
    >
      <ListItemIcon>{props.icon && props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
}
