import { makeStyles } from "@material-ui/core";
import * as React from "react";

export interface EmailLinkProps {
  email: string;
}

var useStyles = makeStyles((theme) => ({
  emailLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));
export const EmailLink: React.FunctionComponent<EmailLinkProps> = (props) => {
  const styles = useStyles();
  return (
    <a href={`mailto:${props.email}`} className={styles.emailLink}>
      {props.email}
    </a>
  );
};
