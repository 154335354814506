/**
 * @generated SignedSource<<3a4b00ec71820d45a5f48c94589c91e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RevokeConfirmationDialog_RevokeInvoicePurchaseMutation$variables = {
  reference: string;
  userUid: string;
};
export type RevokeConfirmationDialog_RevokeInvoicePurchaseMutation$data = {
  readonly revokeInvoicePurchase: {
    readonly success: boolean;
  };
};
export type RevokeConfirmationDialog_RevokeInvoicePurchaseMutation = {
  response: RevokeConfirmationDialog_RevokeInvoicePurchaseMutation$data;
  variables: RevokeConfirmationDialog_RevokeInvoicePurchaseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reference"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userUid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reference",
        "variableName": "reference"
      },
      {
        "kind": "Variable",
        "name": "userUid",
        "variableName": "userUid"
      }
    ],
    "concreteType": "InvoicePurchaseRevoked",
    "kind": "LinkedField",
    "name": "revokeInvoicePurchase",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RevokeConfirmationDialog_RevokeInvoicePurchaseMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RevokeConfirmationDialog_RevokeInvoicePurchaseMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c012bba1250de2f914a0ad57caefc499",
    "id": null,
    "metadata": {},
    "name": "RevokeConfirmationDialog_RevokeInvoicePurchaseMutation",
    "operationKind": "mutation",
    "text": "mutation RevokeConfirmationDialog_RevokeInvoicePurchaseMutation(\n  $userUid: ID!\n  $reference: String!\n) {\n  revokeInvoicePurchase(userUid: $userUid, reference: $reference) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "4667712a28ccdff28a08e74a03feb329";

export default node;
