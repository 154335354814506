import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { spacing3 } from "../../crossCutting/layoutConstants";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import type { EnterPaymentForm_ReceiveBankTransferPaymentMutation } from "./__generated__/EnterPaymentForm_ReceiveBankTransferPaymentMutation.graphql";

const useStyles = makeStyles((t) => ({
  dialogRow: {
    paddingBottom: t.spacing(spacing3),
  },
}));

export const EnterPaymentForm: React.FunctionComponent<{
  userUid: string;
  invoice: {
    amountChf: number;
    reference: string | undefined;
    createdSeconds: number;
  };
  onClose: () => void;
}> = (props) => {
  const handleSave = async () => {
    await new Promise<void>((resolve, reject) =>
      commit({
        variables: {
          userUid: props.userUid,
          reference,
          amountChf: parseInt(amount),
          transferDateSeconds: new Date(transferDateString).valueOf() / 1000,
        },
        optimisticUpdater(store) {
          const user = store.get(props.userUid);
          if (!user) return;
          const t = user.getLinkedRecords("openInvoices") ?? [];
          user.setLinkedRecords(
            t.filter((r) => r.getValue("reference") !== reference),
            "openInvoices"
          );
        },
        updater(store) {
          const user = store.get(props.userUid);
          if (!user) return;
          const t = user.getLinkedRecords("openInvoices") ?? [];
          user.setLinkedRecords(
            t.filter((r) => r.getValue("reference") !== reference),
            "openInvoices"
          );
        },
        onCompleted(_, e) {
          e ? reject(e) : resolve();
        },
      })
    );
    props.onClose();
  };
  const [amount, setAmount] = useState(props.invoice.amountChf.toString());
  const [reference, setReference] = useState(props.invoice.reference ?? "");
  const [transferDateString, setTransferDateString] = useState(
    new Date(props.invoice.createdSeconds * 1000).toJSON().substring(0, 10)
  );
  const [commit, isMutInFlight] =
    useMutation<EnterPaymentForm_ReceiveBankTransferPaymentMutation>(
      receiveBankTransferPaymentMutation
    );
  const classes = useStyles();
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Infos zur Zahlung</DialogTitle>
      <DialogContent>
        <div className={classes.dialogRow}>
          <TextField
            fullWidth
            inputMode="numeric"
            label="Referenz"
            value={reference}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setReference(e.currentTarget.value)}
            required
          />
        </div>
        <div className={classes.dialogRow}>
          <TextField
            fullWidth
            inputMode="numeric"
            label="Betrag (CHF)"
            value={amount}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setAmount(e.currentTarget.value)}
            required
          />
        </div>
        <div className={classes.dialogRow}>
          <TextField
            autoFocus
            fullWidth
            type="date"
            label="Zahlungsdatum"
            InputLabelProps={{ shrink: true }}
            value={transferDateString}
            onChange={(e) => setTransferDateString(e.currentTarget.value)}
            required
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button onClick={handleSave} color="primary" disabled={isMutInFlight}>
          Bezahlt
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const receiveBankTransferPaymentMutation = graphql`
  mutation EnterPaymentForm_ReceiveBankTransferPaymentMutation(
    $userUid: ID!
    $reference: String!
    $amountChf: Float!
    $transferDateSeconds: Int!
  ) {
    receiveBankTransferPayment(
      userUid: $userUid
      reference: $reference
      amountChf: $amountChf
      transferDateSeconds: $transferDateSeconds
    ) {
      success
    }
  }
`;
