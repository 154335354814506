import { initializeApp, getApps, getApp } from "firebase/app";
import { config } from "./config";
import "firebase/auth";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseApp = getApps().length
  ? getApp()
  : initializeApp(config.firebase.appConfig);

if (config.firebase.emulator) {
  connectAuthEmulator(getAuth(firebaseApp), "http://172.20.10.11:9099");
}
