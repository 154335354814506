import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import * as React from "react";
import graphql from "babel-plugin-relay/macro";
import { RevokeConfirmationDialog_RevokeInvoicePurchaseMutation } from "./__generated__/RevokeConfirmationDialog_RevokeInvoicePurchaseMutation.graphql";
import { useMutation } from "react-relay";

export const RevokeConfirmationDialog: React.FunctionComponent<{
  userUid: string;
  invoice: {
    reference: string;
  };
  onClose: () => void;
}> = (props) => {
  const [commit, isMutInFlight] =
    useMutation<RevokeConfirmationDialog_RevokeInvoicePurchaseMutation>(
      revokeInvoicePurchaseMutation
    );

  const handleSave = async () => {
    await new Promise<void>((resolve, reject) =>
      commit({
        variables: {
          userUid: props.userUid,
          reference: props.invoice.reference,
        },
        optimisticUpdater(store) {
          const user = store.get(props.userUid);
          if (!user) return;
          const t = user.getLinkedRecords("openInvoices") ?? [];
          user.setLinkedRecords(
            t.filter(
              (r) => r.getValue("reference") !== props.invoice.reference
            ),
            "openInvoices"
          );
        },
        updater(store) {
          const user = store.get(props.userUid);
          if (!user) return;
          const t = user.getLinkedRecords("openInvoices") ?? [];
          user.setLinkedRecords(
            t.filter(
              (r) => r.getValue("reference") !== props.invoice.reference
            ),
            "openInvoices"
          );
        },
        onCompleted(_, e) {
          e ? reject(e) : resolve();
        },
      })
    );
    props.onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Zusatzfunktionen entziehen?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Wurde die Rechnung nicht bezahlt und die Zusatzfunktionen können
          entzogen werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={isMutInFlight}>
          So lassen
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={isMutInFlight}
          autoFocus
        >
          Entziehen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const revokeInvoicePurchaseMutation = graphql`
  mutation RevokeConfirmationDialog_RevokeInvoicePurchaseMutation(
    $userUid: ID!
    $reference: String!
  ) {
    revokeInvoicePurchase(userUid: $userUid, reference: $reference) {
      success
    }
  }
`;
