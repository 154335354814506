import React, { FunctionComponent } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";
import {
  SalesPageQuery,
  SalesPageQuery$data,
} from "./__generated__/SalesPageQuery.graphql";
import { SalesChart } from "./SalesChart";
import _ from "lodash";
import {
  Container,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { spacing3 } from "../../crossCutting/layoutConstants";
import clsx from "clsx";

const useStyles = makeStyles((t) => ({
  paper: {
    padding: t.spacing(spacing3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  numbersPaper: {
    display: "flex",
    flexDirection: "column",
  },
  numbers: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  numberCell: {
    textAlign: "right",
  },
  tableFooterCell: {
    fontWeight: "bold",
    fontSize: t.typography.body2.fontSize,
    color: t.palette.text.primary,
  },
}));

export const SalesPage: React.FunctionComponent<{}> = () => {
  return (
    <QueryRenderer<SalesPageQuery>
      environment={environment}
      query={graphql`
        query SalesPageQuery {
          sales {
            purchaseDateSeconds
            platform
            priceChf
            productNameDe
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <div>Loading...</div>;
        }
        return <PageContent queryResponse={props} />;
      }}
    />
  );
};

const PageContent: FunctionComponent<{
  queryResponse: SalesPageQuery$data;
}> = (props) => {
  const classes = useStyles();
  const convertedToDate = _(props.queryResponse.sales).map((t) => ({
    ...t,
    purchaseDate: new Date(t.purchaseDateSeconds * 1000),
  }));
  const years = convertedToDate
    .map((x) => x.purchaseDate.getFullYear())
    .uniq()
    .orderBy((x) => x, "desc")
    .value();
  const [year, setYear] = React.useState(years[0]);
  const beginningOfMonthDates = convertedToDate
    .filter((t) => t.purchaseDate.getFullYear() === year)
    .map((t) => ({
      ...t,
      x: new Date(t.purchaseDate.getFullYear(), t.purchaseDate.getMonth()),
    }));
  const byProduct = beginningOfMonthDates.groupBy((t) => t.productNameDe);
  const tableSource = byProduct
    .map((group, productNameDe) => ({
      productNameDe,
      total: _(group).sumBy((g) => g.priceChf),
      ios: _(group)
        .filter((g) => g.platform === "ios")
        .sumBy((g) => g.priceChf),
      web: _(group)
        .filter((g) => g.platform === "web")
        .sumBy((g) => g.priceChf),
    }))
    .value();
  const chartSource = byProduct
    .map((group, productNameDe) => ({
      productNameDe,
      group,
    }))
    .value();
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Parameters */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div>
              <Select
                value={year}
                onChange={(e) => setYear(parseInt(e.target.value as any))}
              >
                {years.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Paper>
        </Grid>
        {/* Chart */}
        <Grid item xs={12} sm={8} lg={6}>
          <Paper className={classes.paper}>
            <div>
              <Typography align="center" component="h2" variant="h6">
                App Verkäufe {year}
              </Typography>
              <div>
                {chartSource.length ? <SalesChart data={chartSource} /> : null}
              </div>
            </div>
          </Paper>
        </Grid>
        {/* Numbers */}
        <Grid item xs={12} sm={4} lg={6}>
          <Paper className={clsx(classes.paper, classes.numbersPaper)}>
            <Typography align="center" component="h2" variant="h6">
              App Verkäufe {year}
            </Typography>
            <div className={classes.numbers}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Umsatz in CHF</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>iOS</TableCell>
                    <TableCell>Web</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableSource.map((i) => (
                    <TableRow key={i.productNameDe}>
                      <TableCell>{i.productNameDe}</TableCell>
                      <TableCell className={classes.numberCell}>
                        {i.total}
                      </TableCell>
                      <TableCell className={classes.numberCell}>
                        {i.ios}
                      </TableCell>
                      <TableCell className={classes.numberCell}>
                        {i.web}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className={classes.tableFooterCell}>
                      Total
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tableFooterCell,
                        classes.numberCell
                      )}
                    >
                      {_(tableSource).sumBy((i) => i.total)}
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tableFooterCell,
                        classes.numberCell
                      )}
                    >
                      {_(tableSource).sumBy((i) => i.ios)}
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tableFooterCell,
                        classes.numberCell
                      )}
                    >
                      {_(tableSource).sumBy((i) => i.web)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>{/* <Orders /> */}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
