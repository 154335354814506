/**
 * @generated SignedSource<<76a8b5ab5ddbc09809fba7097af93f16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenInvoicesTable_user$data = {
  readonly id: string;
  readonly openInvoices: ReadonlyArray<{
    readonly reference: string;
    readonly " $fragmentSpreads": FragmentRefs<"OpenInvoicesRow_row">;
  }>;
  readonly " $fragmentType": "OpenInvoicesTable_user";
};
export type OpenInvoicesTable_user$key = {
  readonly " $data"?: OpenInvoicesTable_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenInvoicesTable_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenInvoicesTable_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OpenInvoice",
      "kind": "LinkedField",
      "name": "openInvoices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OpenInvoicesRow_row"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5d8e64c33e28ef93b69e5730f82d03a3";

export default node;
