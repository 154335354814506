import _ from "lodash";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryStack,
  VictoryTheme,
} from "victory";

const colorsToPickFrom = ["blue", "orange", "gray", "green"];
export const SalesChart: React.FunctionComponent<{
  data: { productNameDe: string; group: { x: Date; priceChf: number }[] }[];
}> = ({ data: dt }) => {
  // dt = [
  //   {
  //     productNameDe: "p1",
  //     group: [
  //       { x: new Date("2021-01-01"), priceChf: 60 },
  //       { x: new Date("2021-01-01"), priceChf: 59 },
  //       { x: new Date("2021-02-01"), priceChf: 60 },
  //       { x: new Date("2021-03-01"), priceChf: 60 },
  //       { x: new Date("2021-05-01"), priceChf: 60 },
  //     ],
  //   },
  //   {
  //     productNameDe: "p2",
  //     group: [
  //       { x: new Date("2021-01-01"), priceChf: 10 },
  //       { x: new Date("2021-03-01"), priceChf: 9 },
  //     ],
  //   },
  // ];
  const oneItemPerMonth = dt.map((g) => ({
    productNameDe: g.productNameDe,
    group: _(g.group)
      .groupBy((gg) => gg.x)
      .map((gg, x) => ({
        x: new Date(x),
        y: _(gg).sumBy((ggg) => ggg.priceChf),
      }))
      .sortBy((gg) => gg.x)
      .value(),
  }));

  const sharedAxisStyles = {
    tickLabels: {
      fontSize: 10,
    },
    axisLabel: {
      padding: 39,
      fontSize: 13,
      fontStyle: "italic",
    },
  };

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: "time" }}
      domainPadding={{ x: 5 }}
    >
      <VictoryStack colorScale={colorsToPickFrom}>
        {oneItemPerMonth.map(({ productNameDe, group }) => {
          return (
            <VictoryBar
              key={productNameDe}
              data={group}
              style={{
                data: { strokeWidth: 1, stroke: "hsl(324, 10%, 20%)" },
              }}
            />
          );
        })}
      </VictoryStack>
      <VictoryAxis
        style={sharedAxisStyles}
        tickCount={12}
        tickFormat={(date: Date) =>
          date.toLocaleString("default", { month: "short" })
        }
      />
      <VictoryAxis
        dependentAxis
        label="Umsatz CHF"
        style={sharedAxisStyles}
        tickFormat={(v: number) => v.toFixed(0)}
      />
      <VictoryLegend
        orientation="horizontal"
        data={dt.map(({ productNameDe }, i) => ({
          name: productNameDe,
          symbol: { fill: colorsToPickFrom[i] },
        }))}
      />
    </VictoryChart>
  );
};
