import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import ClearedInvoicesRow from "./ClearedInvoicesRow";
import { ClearedInvoicesTable_user$data } from "./__generated__/ClearedInvoicesTable_user.graphql";

const Tbl: React.FunctionComponent<{
  user: ClearedInvoicesTable_user$data;
}> = ({ user }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Referenz</TableCell>
          <TableCell>Rechnungsdatum</TableCell>
          <TableCell>Betrag</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Produkt</TableCell>
          <TableCell>Eingegangen</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {user.clearedInvoices?.map((r) => (
          <ClearedInvoicesRow key={r.reference} row={r} userUid={user.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default createFragmentContainer(Tbl, {
  user: graphql`
    fragment ClearedInvoicesTable_user on User {
      id
      clearedInvoices {
        reference
        ...ClearedInvoicesRow_row
      }
    }
  `,
});
