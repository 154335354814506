/**
 * @generated SignedSource<<cbd79a8702ea226afeba1ffb81974dc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClearedInvoicesRow_row$data = {
  readonly amountChf: number;
  readonly createdSeconds: number;
  readonly customer: {
    readonly name: string;
  };
  readonly reference: string;
  readonly referenceAsOnInvoice: string;
  readonly transferDateSeconds: number | null;
  readonly webProductId: string;
  readonly " $fragmentType": "ClearedInvoicesRow_row";
};
export type ClearedInvoicesRow_row$key = {
  readonly " $data"?: ClearedInvoicesRow_row$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClearedInvoicesRow_row">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClearedInvoicesRow_row",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceAsOnInvoice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountChf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webProductId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferDateSeconds",
      "storageKey": null
    }
  ],
  "type": "ClearedInvoice",
  "abstractKey": null
};

(node as any).hash = "7bdc14af87456f2cb62e0c9f8e17d6a1";

export default node;
