import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export { default as AddIcon } from "@material-ui/icons/Add";
export { default as CloseIcon } from "@material-ui/icons/Close";
export { default as MenuIcon } from "@material-ui/icons/Menu";
export { default as PersonIcon } from "@material-ui/icons/Person";
export { default as GroupIcon } from "@material-ui/icons/Group";
export { default as PersonAddIcon } from "@material-ui/icons/PersonAdd";
export { default as BackIcon } from "@material-ui/icons/NavigateBefore";
export { default as NextIcon } from "@material-ui/icons/NavigateNext";
export { default as DeleteIcon } from "@material-ui/icons/Delete";
export { default as DownIcon } from "@material-ui/icons/ArrowDropDown";
export { default as UpIcon } from "@material-ui/icons/ArrowDropUp";
export { default as SearchIcon } from "@material-ui/icons/Search";
export { default as MoreIcon } from "@material-ui/icons/MoreHoriz";
export { default as CheckIcon } from "@material-ui/icons/Check";
export { default as EditIcon } from "@material-ui/icons/Edit";
export { default as TodoIcon } from "@material-ui/icons/PriorityHigh";
export { default as UploadIcon } from "@material-ui/icons/CloudUpload";
export { default as ExpandIcon } from "@material-ui/icons/ExpandLess";
export { default as CollapseIcon } from "@material-ui/icons/ExpandMore";
export { default as ShowIcon } from "@material-ui/icons/Visibility";
export { default as HideIcon } from "@material-ui/icons/VisibilityOff";
export { default as ChangeOrderIcon } from "@material-ui/icons/SwapVert";
export { default as SettingsIcon } from "@material-ui/icons/Settings";
export { default as ReceiptIcon } from "@material-ui/icons/Receipt";
export { default as ChartIcon } from "@material-ui/icons/ShowChart";

export const ExcelIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13z" />
  </SvgIcon>
);
