import { TableCell, TableRow } from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { useAuth } from "../../Auth";
import { invoiceUrl } from "../../invoices";
import { ClearedInvoicesRow_row$data } from "./__generated__/ClearedInvoicesRow_row.graphql";

const Row: React.FunctionComponent<{
  row: ClearedInvoicesRow_row$data;
  userUid: string;
}> = (props) => {
  const r = props.row;
  const user = useAuth();
  const [token, setToken] = useState("");
  useEffect(() => {
    if (!user) return;
    user.getIdToken().then(setToken);
  }, [user]);

  return token ? (
    <>
      <TableRow key={r.reference}>
        <TableCell align="right">{r.reference}</TableCell>
        <TableCell>{r.referenceAsOnInvoice}</TableCell>
        <TableCell align="right">
          {new Date(r.createdSeconds * 1000).toLocaleDateString()}
        </TableCell>
        <TableCell align="right">{r.amountChf}</TableCell>
        <TableCell>{r.customer.name}</TableCell>
        <TableCell>{r.webProductId}</TableCell>
        <TableCell align="right">
          {r.transferDateSeconds
            ? new Date(r.transferDateSeconds * 1000).toLocaleDateString()
            : null}
        </TableCell>
        <TableCell>
          <a
            href={invoiceUrl(props.userUid, r.reference, token)}
            target="_blank"
            rel="noreferrer"
          >
            PDF
          </a>
        </TableCell>
      </TableRow>
    </>
  ) : null;
};

export default createFragmentContainer(Row, {
  row: graphql`
    fragment ClearedInvoicesRow_row on ClearedInvoice {
      reference
      referenceAsOnInvoice
      createdSeconds
      amountChf
      customer {
        name
      }
      webProductId
      transferDateSeconds
    }
  `,
});
