/**
 * @generated SignedSource<<91e9ebe996304ca4a7f3ec19f8dffce8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenInvoicesRow_row$data = {
  readonly amountChf: number;
  readonly createdSeconds: number;
  readonly customer: {
    readonly name: string;
  };
  readonly isRevoked: boolean;
  readonly reference: string;
  readonly referenceAsOnInvoice: string;
  readonly webProductId: string;
  readonly " $fragmentType": "OpenInvoicesRow_row";
};
export type OpenInvoicesRow_row$key = {
  readonly " $data"?: OpenInvoicesRow_row$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenInvoicesRow_row">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenInvoicesRow_row",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceAsOnInvoice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountChf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webProductId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRevoked",
      "storageKey": null
    }
  ],
  "type": "OpenInvoice",
  "abstractKey": null
};

(node as any).hash = "84168191a681f2f0bd901b920d5fe9a9";

export default node;
