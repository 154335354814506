import "firebase/auth";
import { User, getAuth } from "firebase/auth";
import React, { useState } from "react";
import SignIn from "./SignIn";

const authContext = React.createContext<User | undefined>(undefined);

export const ProvideAuth: React.FunctionComponent<{}> = (props) => {
  const [user, setUser] = useState<User | undefined | null>();
  getAuth().onAuthStateChanged((u) => {
    setUser(u);
  });

  if (user) {
    return (
      <authContext.Provider value={user}>{props.children}</authContext.Provider>
    );
  } else if (user === null) {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
  return null;
};

export function useAuth() {
  const user = React.useContext(authContext);
  return user;
}
