export const config = {
  firebase: {
    appConfig: {
      apiKey: "AIzaSyDPlndEwjvNdZCEhEDG8scmXGg-PuoQS1o",
      authDomain: "swisszucht.firebaseapp.com",
      projectId: "swisszucht",
      storageBucket: "swisszucht.appspot.com",
      messagingSenderId: "246814530540",
      appId: "1:246814530540:web:22636a3ca6146c20cf7ec9",
      measurementId: "G-KF2Y454KHX",
    },
    regionZurich: "europe-west6",
    emulator: process.env.REACT_APP_FIREBASE_EMULATOR === "true",
  },
  graphQlUrl: process.env.REACT_APP_GRAPHQL_URL as string,
  plainHttpUrl: process.env.REACT_APP_PLAIN_HTTP_URL as string,
};
