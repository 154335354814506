import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import * as React from "react";
import { useAuth } from "./Auth";
import { PersonIcon } from "./crossCutting/icons";
import "firebase/auth";
import { getAuth } from "firebase/auth";
export interface CurrentUserProps {}

const CurrentUser: React.FunctionComponent<CurrentUserProps> = () => {
  const user = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSignout = () => {
    setAnchorEl(null);
    getAuth().signOut();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!user) return null;
  return (
    <>
      <Tooltip title="Benutzerprofil">
        <IconButton color="inherit" aria-label="profile" onClick={handleClick}>
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem disabled>{user.email}</MenuItem>
        <MenuItem onClick={handleSignout}>Abmelden</MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUser;
