/**
 * @generated SignedSource<<e5d68acb1cdca188fee9caec2f7eb52e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SalesPageQuery$variables = {};
export type SalesPageQuery$data = {
  readonly sales: ReadonlyArray<{
    readonly platform: string;
    readonly priceChf: number;
    readonly productNameDe: string;
    readonly purchaseDateSeconds: number;
  }>;
};
export type SalesPageQuery = {
  response: SalesPageQuery$data;
  variables: SalesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SalesRecord",
    "kind": "LinkedField",
    "name": "sales",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "purchaseDateSeconds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "platform",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceChf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productNameDe",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SalesPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "422997dbaf589d981d755a49de6c2899",
    "id": null,
    "metadata": {},
    "name": "SalesPageQuery",
    "operationKind": "query",
    "text": "query SalesPageQuery {\n  sales {\n    purchaseDateSeconds\n    platform\n    priceChf\n    productNameDe\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa12f16fb6ffc2dff9cec87cbb6acf77";

export default node;
