import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";
import { config } from "./config";
import { getAuth } from "firebase/auth";

const fetchQuery: FetchFunction = async (operation, variables: any) => {
  const user = getAuth().currentUser;
  if (!user) {
    throw new Error("not authenticated");
  }
  const idToken = await user.getIdToken();
  const response = await fetch(config.graphQlUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + idToken,
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });
  return response.json();
};

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
