import {
  Button,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../environment";
import {
  UsersPageQuery,
  UsersPageQuery$data,
} from "./__generated__/UsersPageQuery.graphql";
import { Link as RouterLink } from "react-router-dom";
import { EmailLink } from "../../components/EmailLink";
import { userExcelUrl } from "../../users";
import { useAuth } from "../../Auth";
import { ExcelIcon } from "../../crossCutting/icons";

const useStyles = makeStyles((t) => ({
  revokedInvoice: {
    color: t.palette.grey[500],
  },
}));

export const UsersPage: React.FunctionComponent<{}> = () => {
  return (
    <QueryRenderer<UsersPageQuery>
      environment={environment}
      query={graphql`
        query UsersPageQuery {
          users {
            id
            createdSeconds
            email
            hasActiveEntitlements
            initialPlatform
            lastRefreshSeconds
            lastSignInSeconds
            name
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <div>Loading...</div>;
        }
        return <PageContent queryResponse={props} />;
      }}
    />
  );
};

const PageContent: React.FunctionComponent<{
  queryResponse: UsersPageQuery$data;
}> = (props) => {
  const user = useAuth();
  const rows = props.queryResponse.users;
  const [token, setToken] = useState("");
  useEffect(() => {
    if (!user) return;
    user.getIdToken().then(setToken);
  }, [user]);
  return (
    <>
      <div>
        <Button
          variant="text"
          color="primary"
          href={userExcelUrl(token)}
          startIcon={<ExcelIcon />}
          title="Benutzerliste als Excel herunterladen"
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Benutzer ID</TableCell>
            <TableCell>E-Mail</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Registriert am</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Plattform</TableCell>
            <TableCell>Letzter Login</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((u) => (
            <TableRow key={u.id}>
              <TableCell>
                <Link component={RouterLink} to={`/users/${u.id}`}>
                  {u.id}
                </Link>
              </TableCell>
              <TableCell>{u.email && <EmailLink email={u.email} />}</TableCell>
              <TableCell>{u.name}</TableCell>
              <TableCell>
                {u.createdSeconds
                  ? new Date(u.createdSeconds * 1000).toLocaleDateString()
                  : undefined}
              </TableCell>
              <TableCell>{u.hasActiveEntitlements ? "Kunde" : null}</TableCell>
              <TableCell>{u.initialPlatform}</TableCell>
              <TableCell>
                {calculateLastLogin(u)?.toLocaleDateString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

function calculateLastLogin(user: {
  lastRefreshSeconds: number | null;
  lastSignInSeconds: number | null;
}) {
  const max = Math.max(
    user.lastRefreshSeconds ?? 0,
    user.lastSignInSeconds ?? 0
  );
  return max ? new Date(max * 1000) : undefined;
}
