/**
 * @generated SignedSource<<420add654817e8e2b3516545940d5a41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UsersPageQuery$variables = {};
export type UsersPageQuery$data = {
  readonly users: ReadonlyArray<{
    readonly createdSeconds: number | null;
    readonly email: string | null;
    readonly hasActiveEntitlements: boolean;
    readonly id: string;
    readonly initialPlatform: string;
    readonly lastRefreshSeconds: number | null;
    readonly lastSignInSeconds: number | null;
    readonly name: string | null;
  }>;
};
export type UsersPageQuery = {
  response: UsersPageQuery$data;
  variables: UsersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdSeconds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasActiveEntitlements",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initialPlatform",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastRefreshSeconds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastSignInSeconds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UsersPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e5b13d8f04e9ca2f3662b8f89f5cf699",
    "id": null,
    "metadata": {},
    "name": "UsersPageQuery",
    "operationKind": "query",
    "text": "query UsersPageQuery {\n  users {\n    id\n    createdSeconds\n    email\n    hasActiveEntitlements\n    initialPlatform\n    lastRefreshSeconds\n    lastSignInSeconds\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a270a7dec13ae8d6c7a83b1e051ad89a";

export default node;
