/**
 * @generated SignedSource<<2f8a35645d6ac832d65634b55a965a48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPageQuery$variables = {
  userUid: string;
};
export type UserPageQuery$data = {
  readonly user: {
    readonly email: string | null;
    readonly id: string;
    readonly initialPlatform: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ClearedInvoicesTable_user" | "OpenInvoicesTable_user">;
  } | null;
};
export type UserPageQuery = {
  response: UserPageQuery$data;
  variables: UserPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userUid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userUid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initialPlatform",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceAsOnInvoice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdSeconds",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountChf",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webProductId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OpenInvoicesTable_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClearedInvoicesTable_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenInvoice",
            "kind": "LinkedField",
            "name": "openInvoices",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRevoked",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClearedInvoice",
            "kind": "LinkedField",
            "name": "clearedInvoices",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transferDateSeconds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d71872213740bfbf59da0a398747df89",
    "id": null,
    "metadata": {},
    "name": "UserPageQuery",
    "operationKind": "query",
    "text": "query UserPageQuery(\n  $userUid: ID!\n) {\n  user(id: $userUid) {\n    id\n    email\n    name\n    initialPlatform\n    ...OpenInvoicesTable_user\n    ...ClearedInvoicesTable_user\n  }\n}\n\nfragment ClearedInvoicesRow_row on ClearedInvoice {\n  reference\n  referenceAsOnInvoice\n  createdSeconds\n  amountChf\n  customer {\n    name\n  }\n  webProductId\n  transferDateSeconds\n}\n\nfragment ClearedInvoicesTable_user on User {\n  id\n  clearedInvoices {\n    reference\n    ...ClearedInvoicesRow_row\n  }\n}\n\nfragment OpenInvoicesRow_row on OpenInvoice {\n  reference\n  referenceAsOnInvoice\n  createdSeconds\n  amountChf\n  customer {\n    name\n  }\n  webProductId\n  isRevoked\n}\n\nfragment OpenInvoicesTable_user on User {\n  id\n  openInvoices {\n    reference\n    ...OpenInvoicesRow_row\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b4df93021aee02808376b2adadc5fe8";

export default node;
