import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { QueryRenderer } from "react-relay";
import { useParams } from "react-router-dom";
import { spacing3 } from "../../crossCutting/layoutConstants";
import environment from "../../environment";
import OpenInvoicesTable from "./OpenInvoicesTable";
import ClearedInvoicesTable from "./ClearedInvoicesTable";
import {
  UserPageQuery,
  UserPageQuery$data,
} from "./__generated__/UserPageQuery.graphql";

const useStyles = makeStyles((t) => ({
  paper: {
    padding: t.spacing(spacing3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
}));
export const UserPage: React.FunctionComponent<{}> = () => {
  const { userUid } = useParams<{ userUid: string }>();
  return (
    <QueryRenderer<UserPageQuery>
      environment={environment}
      query={graphql`
        query UserPageQuery($userUid: ID!) {
          user(id: $userUid) {
            id
            email
            name
            initialPlatform
            ...OpenInvoicesTable_user
            ...ClearedInvoicesTable_user
          }
        }
      `}
      variables={{ userUid }}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <div>Loading...</div>;
        }
        if (!props.user) {
          return <div>Benutzer nicht gefunden</div>;
        }
        return <PageContent user={props.user} />;
      }}
    />
  );
};

const PageContent: React.FunctionComponent<{
  user: NonNullable<UserPageQuery$data["user"]>;
}> = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Basic user info */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div>
              <Typography component="h2" variant="h4">
                {props.user.email}
              </Typography>
              <div>{props.user.id}</div>
              {props.user.name && <div>{props.user.name}</div>}
              <div>{props.user.initialPlatform}</div>
            </div>
          </Paper>
        </Grid>
        {/* Open invoices */}
        {/* {(props.user as any).openInvoices && ( */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h3" variant="h6">
              Offene Rechnungen
            </Typography>
            <div>
              <OpenInvoicesTable user={props.user} />
            </div>
          </Paper>
        </Grid>
        {/* )} */}
        {/* Cleared invoices */}
        {/* {(props.user as any).clearedInvoices && ( */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h3" variant="h6">
              Bezahlte Rechnungen
            </Typography>
            <div>
              <ClearedInvoicesTable user={props.user} />
            </div>
          </Paper>
        </Grid>
        {/* )} */}
      </Grid>
    </Container>
  );
};
