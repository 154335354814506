/**
 * @generated SignedSource<<17286be1e0153a16c263baf5f07ba92f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnterPaymentForm_ReceiveBankTransferPaymentMutation$variables = {
  amountChf: number;
  reference: string;
  transferDateSeconds: number;
  userUid: string;
};
export type EnterPaymentForm_ReceiveBankTransferPaymentMutation$data = {
  readonly receiveBankTransferPayment: {
    readonly success: boolean;
  };
};
export type EnterPaymentForm_ReceiveBankTransferPaymentMutation = {
  response: EnterPaymentForm_ReceiveBankTransferPaymentMutation$data;
  variables: EnterPaymentForm_ReceiveBankTransferPaymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountChf"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reference"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transferDateSeconds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userUid"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amountChf",
        "variableName": "amountChf"
      },
      {
        "kind": "Variable",
        "name": "reference",
        "variableName": "reference"
      },
      {
        "kind": "Variable",
        "name": "transferDateSeconds",
        "variableName": "transferDateSeconds"
      },
      {
        "kind": "Variable",
        "name": "userUid",
        "variableName": "userUid"
      }
    ],
    "concreteType": "BankTransferPaymentReceived",
    "kind": "LinkedField",
    "name": "receiveBankTransferPayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EnterPaymentForm_ReceiveBankTransferPaymentMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EnterPaymentForm_ReceiveBankTransferPaymentMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "507b6110d548d2aceaa8d64b8f219bd8",
    "id": null,
    "metadata": {},
    "name": "EnterPaymentForm_ReceiveBankTransferPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation EnterPaymentForm_ReceiveBankTransferPaymentMutation(\n  $userUid: ID!\n  $reference: String!\n  $amountChf: Float!\n  $transferDateSeconds: Int!\n) {\n  receiveBankTransferPayment(userUid: $userUid, reference: $reference, amountChf: $amountChf, transferDateSeconds: $transferDateSeconds) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "154c64fc8e04dd43a11c0be9f0eba598";

export default node;
