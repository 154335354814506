import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { useAuth } from "../../Auth";
import { invoiceUrl } from "../../invoices";
import { EnterPaymentForm } from "./EnterPaymentForm";
import { RevokeConfirmationDialog } from "./RevokeConfirmationDialog";
import { OpenInvoicesRow_row$data } from "./__generated__/OpenInvoicesRow_row.graphql";

const useStyles = makeStyles((t) => ({
  revokedInvoice: {
    color: t.palette.grey[500],
  },
}));

const Row: React.FunctionComponent<{
  row: OpenInvoicesRow_row$data;
  userUid: string;
}> = (props) => {
  const r = props.row;
  const user = useAuth();
  const [token, setToken] = useState("");
  useEffect(() => {
    if (!user) return;
    user.getIdToken().then(setToken);
  }, [user]);
  const [invoiceBeingEdited, setInvoiceBeingEdited] = useState<
    OpenInvoicesRow_row$data | undefined
  >();
  const [invoiceBeingRevoked, setInvoiceBeingRevoked] = useState<
    OpenInvoicesRow_row$data | undefined
  >();

  const classes = useStyles();
  return token ? (
    <>
      <TableRow key={r.reference}>
        <TableCell
          align="right"
          className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
        >
          {r.reference}
        </TableCell>
        <TableCell className={clsx({ [classes.revokedInvoice]: r.isRevoked })}>
          {r.referenceAsOnInvoice}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
        >
          {new Date(r.createdSeconds * 1000).toLocaleDateString()}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({ [classes.revokedInvoice]: r.isRevoked })}
        >
          {r.amountChf}
        </TableCell>
        <TableCell className={clsx({ [classes.revokedInvoice]: r.isRevoked })}>
          {r.customer.name}
        </TableCell>
        <TableCell className={clsx({ [classes.revokedInvoice]: r.isRevoked })}>
          {r.webProductId}
        </TableCell>
        <TableCell>
          <a
            href={invoiceUrl(props.userUid, r.reference, token)}
            target="_blank"
            rel="noreferrer"
          >
            PDF
          </a>
        </TableCell>
        <TableCell>
          <Button
            variant="text"
            color="primary"
            onClick={() => setInvoiceBeingEdited(r)}
          >
            Bezahlt
          </Button>
        </TableCell>
        <TableCell>
          <Button
            variant="text"
            color="primary"
            onClick={() => setInvoiceBeingRevoked(r)}
            disabled={r.isRevoked}
          >
            Entziehen
          </Button>
        </TableCell>
      </TableRow>
      {invoiceBeingEdited ? (
        <EnterPaymentForm
          invoice={invoiceBeingEdited}
          userUid={props.userUid}
          onClose={() => setInvoiceBeingEdited(undefined)}
        />
      ) : null}
      {invoiceBeingRevoked ? (
        <RevokeConfirmationDialog
          invoice={invoiceBeingRevoked}
          userUid={props.userUid}
          onClose={() => setInvoiceBeingRevoked(undefined)}
        />
      ) : null}
    </>
  ) : null;
};

export default createFragmentContainer(Row, {
  row: graphql`
    fragment OpenInvoicesRow_row on OpenInvoice {
      reference
      referenceAsOnInvoice
      createdSeconds
      amountChf
      customer {
        name
      }
      webProductId
      isRevoked
    }
  `,
});
