import Shell from "./Shell";
import { DrawerMenu } from "./DrawerMenu";
import { ProvideAuth } from "./Auth";
import "./firebaseWrapper";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { DashboardPage } from "./pages/DashboardPage";
import { UsersPage } from "./pages/usersPage/UsersPage";
import { UserPage } from "./pages/userPage/UserPage";
import { SalesPage } from "./pages/salesPage/SalesPage";
import { OpenInvoicesPage } from "./pages/openInvoicesPage/OpenInvoicesPage";

function App() {
  return (
    <Router>
      <ProvideAuth>
        <Shell
          loading={false}
          drawerContent={(drawerActions) => <DrawerMenu />}
          location={<div></div>}
          mainContent={
            <Switch>
              <Route path="/openInvoices">
                <OpenInvoicesPage />
              </Route>
              <Route path="/users/:userUid">
                <UserPage />
              </Route>
              <Route path="/users">
                <UsersPage />
              </Route>
              <Route path="/sales">
                <SalesPage />
              </Route>
              <Route path="/">
                <DashboardPage />
              </Route>
            </Switch>
          }
        />
      </ProvideAuth>
    </Router>
  );
}

export default App;
