import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { createFragmentContainer } from "react-relay";
import OpenInvoicesRow from "./OpenInvoicesRow";
import { OpenInvoicesTable_user$data } from "./__generated__/OpenInvoicesTable_user.graphql";

const Tbl: React.FunctionComponent<{
  user: OpenInvoicesTable_user$data;
}> = ({ user }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Referenz</TableCell>
          <TableCell>Rechnungsdatum</TableCell>
          <TableCell>Betrag</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Produkt</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {user.openInvoices?.map((r) => (
          <OpenInvoicesRow key={r.reference} row={r} userUid={user.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default createFragmentContainer(Tbl, {
  user: graphql`
    fragment OpenInvoicesTable_user on User {
      id
      openInvoices {
        reference
        ...OpenInvoicesRow_row
      }
    }
  `,
});
